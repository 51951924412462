import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";

import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
//const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm 
        description={"If you have any topics you want discuss with us, feel free to get in touch with us. For example, it could be an idea for a new module, or an improvement request."}
      />
      <ContactDetails
        description={"We not have an official office yet"}
        cards={[
          {
            title: "Cologne",
            description: (
              <>
                <Address>
                  <AddressLine>Cologne</AddressLine>
                  <AddressLine>Germany - 50667 Cologne</AddressLine>
                </Address>
                <Email>makmarlabs@gmail.com</Email>
                
              </>
            )
          },         
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
