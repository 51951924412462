import React,{useRef} from "react";
import { useParams,useLocation } from 'react-router-dom';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo";
import Features from "components/features/ThreeColWithSideImage.js";

import FeatureWithSteps from "components/features/TwoColWithSteps.js";

import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";

import Footer from "components/footers/MiniCenteredFooter";

import logoImageSrc from "images/modula-logo.png";
import appMockupImageSrc from "images/app-mockup.png";

import ModuleOverview from "components/cards/TabCardGrid"

import DownloadApp from "components/cta/DownloadApp.js";
import AboutUs from "components/cards/ProfileThreeColGrid"

import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

import ImageMarko from "images/team/marko.jpg"; 
import ImageMartin  from "images/team/martin.jpg";



export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const HighlightedText = tw.span`text-primary-500`;

  const location = useLocation()





  return (
    <AnimationRevealPage disabled={location.hash !== ''}>
      <Hero roundedHeaderButton={true} />
      <Features
        id='Why'
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Why is <HighlightedText>modula</HighlightedText> better then the others ?
          </>
        }
      />

      <FeatureWithSteps
        subheading={<Subheading>Start using modula</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={appMockupImageSrc}

      />

      <ModuleOverview 
       id='Modules'
       heading={"Our Modules"}
       tabs={{
         Available: [
          {
            imageSrc:
              "https://images.pexels.com/photos/6693655/pexels-photo-6693655.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            title: "Money-Split",
            content: "Manage all your expenses with friends",
          },
          {
            imageSrc:
              "https://images.pexels.com/photos/4057737/pexels-photo-4057737.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            title: "Todo-List",
            content: "Work together on lists and create shopping list or packing list",
          },           
          {
            imageSrc:
              "https://images.pexels.com/photos/3826577/pexels-photo-3826577.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            title: "Event",
            content: "Create an Event like a soccer match and other user can participate",
          },
          {
            imageSrc:
              "https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            title: "Poll",
            content: "Make an survey or poll directly in the app to find a date or a restaraunt",
          },                           
         ],
         Soon: [
          {
            imageSrc:
              "https://images.pexels.com/photos/5386754/pexels-photo-5386754.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            title: "Calendar",
            content: "Make calendar which can be used of every group member",
          },
          {
            imageSrc:
              "https://images.pexels.com/photos/139764/pexels-photo-139764.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            title: "Image Gallery",
            content: "Use galleries to manage your pictures in a group",
          },
          {
            imageSrc:
              "https://images.pexels.com/photos/39644/robonaut-machines-dexterous-humanoid-39644.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            title: "Much more",
            content: "Much more Modules are comming soon",
          },          
         ]
       }}
      />

      <DownloadApp
      id='GetStarted'
      imageSrc={logoImageSrc}
        text={<>People around you are communicating and organizing using the <HighlightedTextInverse>modula App.</HighlightedTextInverse></>}
      />

      <AboutUs 
        heading = {"Meet These Fine Folks."}
        subheading = {"Our Team"}
        description = {"Hey, we are Marko and Martin and the founders of modula"}
        cards={[
          {
            imageSrc: ImageMarko,
            position: "Founder",
            name: "Marko",
            links: [
              {
                url: "https://twitter.com",
                icon: TwitterIcon,
              },
              {
                url: "https://linkedin.com",
                icon: LinkedinIcon,
              },
              {
                url: "https://github.com",
                icon: GithubIcon,
              },
            ],
          },
          {
            imageSrc: ImageMartin,
            position: "Founder",
            name: "Martin",
            links: [
              {
                url: "https://twitter.com",
                icon: TwitterIcon,
              },
              {
                url: "https://linkedin.com",
                icon: LinkedinIcon,
              },
              {
                url: "https://github.com",
                icon: GithubIcon,
              },
            ],
          },
        ]} 
      />
     
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        description={"Some comments our users gave to us. But please make your own picture of the app"}
        heading={
          <>
            Our users <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing Idea",
            quote:
              "Do you need a overview of your costs in holiday with your friends ? Find a next Date for the next party ? Or just create a shopping list with your partner. All this, and much more is possible in this amazing app",
            customerName: "Anna Maier",
           // customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the variety",
            quote:
              "It was never so easy to organize a things with my friends. Before modula I used several different apps. Now I can do everything in modula.",
            customerName: "Adam Petersen",
            //customerTitle: "Founder, EventsNYC"
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        description={"And we have got the answers to all of them"}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How modula can be for free ?",
            answer:
              "We dont sell any user Data and dont provide adverts in Modula ! Currently the project is fincanced by donations. In future its planned to create enhanced modules which can be bought."
          },
          {
            question: "Can I provide you an Idea for a new Module ?",
            answer:
              "Yes sure, we are always happy about new Ideas. Please use the contact form to send us your amazing module Idea !"
          },
          {
            question: "What technoligy is used to built the app ?",
            answer:
              "Front-End is based on React-Native and Backend based on Googles Firebase Cloud-Platform"
          },
          {
            question: "Do you store any sensetive data about me ?",
            answer:
              "No, all messages and contacts are only saved on your local device ! Messages are only saved on the server until they are delivered to the users"
          }
        ]}
      />

      <Footer />
    </AnimationRevealPage>
  );
}
